$color__accent: hsl(5.6, 78.1%, 57.1%);
$color__light: hsl(206.7, 27.3%, 93.5%);
$color__dark: hsl(210, 29%, 24.3%);
$color__text: hsl(209.4, 28.8%, 32%);

$font__title: "Montserrat", sans-serif;
$font__main: "Neuton", serif;
// Type scale: 12, 14, 16, 18, 20, 24, 30, 36, 48, 60, 72

$bp__sm: 576px;
$bp__md: 768px;
$bp__lg: 992px;

$site__max-width: 992px;

// Font
// Original link: https://fonts.googleapis.com/css?family=Montserrat:700|Neuton:400,400i,700&display=swap

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Neuton';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Neuton Regular'), local('Neuton-Regular'), url(https://fonts.gstatic.com/s/neuton/v12/UMBTrPtMoH62xUZCz4g6.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Neuton';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Neuton Italic'), local('Neuton-Italic'), url(https://fonts.gstatic.com/s/neuton/v12/UMBRrPtMoH62xUZCyrg4Wi8.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Neuton';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Neuton Bold'), local('Neuton-Bold'), url(https://fonts.gstatic.com/s/neuton/v12/UMBQrPtMoH62xUZKdK0vfQr4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Proto

%subtitle {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem 0 0 0;
  font-size: 30px;
  font-weight: 400;
  text-align: center;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    display: inline-block;
    width: 100px;
    height: 4px;
    margin-left: -50px;
    background-color: currentColor;
  }
}

// Base

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font__main;
  font-size: 100%;
  line-height: 1;
  background-color: $color__light;
}

.intro {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color__dark;

  &__inner {
    max-width: 400px;

    @media screen and (min-width: $bp__md) {
      max-width: 600px;
    }
  }

  &__logo {
    width: 100px;
    height: 100px;
    display: block;
    margin: 0 auto;
  }

  &__title {
    margin: 2rem 0;
    padding: 0;
    font-family: $font__title;
    font-size: 60px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: -0.065em;
    text-align: center;
    color: $color__accent;

    @media screen and (min-width: $bp__md) {
      font-size: 72px;
    }
  }

  &__subtitle {
    @extend %subtitle;
    color: $color__light;
  }
}

.about {
  display: flex;
  justify-content: center;
  background-color: $color__light;

  @media screen and (min-width: $bp__sm) {
    min-height: 100%;
  }

  &__inner {
    max-width: $site__max-width;
    padding: 50px 20px;
  }

  &__subtitle {
    @extend %subtitle;
    color: $color__dark;
  }

  &__text {
    padding: 0;

    @media screen and (min-width: $bp__sm) {
      columns: 2;
      column-gap: 20px;
    }

    @media screen and (min-width: $bp__md) {
      columns: 3;
    }

    p {
      margin: 0;
      font-size: 18px;
      line-height: 1.4;
      color: $color__text;
    }

    p + p {
      margin-top: 1rem;
    }

    p:first-of-type {
      color: $color__dark;
      font-size: 24px;
      line-height: 1.3;
    }

    a {
      color: $color__accent;
      text-decoration: none;
      font-weight: 700;

      &:hover {
        color: $color__dark;
      }
    }
  }
}

.resources {
  display: flex;
  margin: 40px 0 0 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  list-style: none;

  &__item {
    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 10px;
    }
  }

  &__link {
    display: block;
    height: 20px;

    > svg {
      width: auto;
      height: 100%;
    }
  }
}

.svg-fill {
  fill: $color__accent;
}

.svg-stroke {
  stroke: $color__accent;
}
